import {
    PESQUISA_LIST
    , PESQUISA_CHANGE
    , PESQUISA_LOADING
    , PESQUISA_VALIDACAO_ADD
    , PESQUISA_VALIDACAO_REMOVE
    , PESQUISA_SAVING
} from './ActionsTypes';





//import { ip } from 'ip';


import * as Api from '../utils/API';

import { PesquisaEntity } from '../entities/PesquisaEntity';

//const ip = require('ip');

const publicIp = require('public-ip');

export const pesquisaHandleChangeAction = (event) => {
    //console.log(event)

    return {
        type: PESQUISA_CHANGE
        , field: event.target.name
        , payload: event.target.value.toString().trim()
    }
}


export const pesquisaListAction = (chave) => (dispatch) =>
    new Promise(function (resolve, reject) {

        dispatch({ type: PESQUISA_LOADING, payload: true })
        dispatch({ type: PESQUISA_LIST, payload: [] })


        publicIp.v4().then(ip => {

            Api.getPesquisa(chave, ip).then(dados => {
                dispatch({ type: PESQUISA_LOADING, payload: false })
    
                let entidade = PesquisaEntity;
    
                if (dados.length > 0) {
    
                    entidade.pesquisaid = dados[0].pesquisaid;
                    entidade.chave = dados[0].chave;
                    entidade.codpac = dados[0].codparc;
                    entidade.cliente = dados[0].cliente;
                    entidade.codconsultor = dados[0].codconsultor;
                    entidade.consultor = dados[0].consultor;
                    entidade.codvend = dados[0].codvend;
                    entidade.vendedor = dados[0].vendedor;
                    entidade.dtvisita = dados[0].dtvisita;
                    entidade.dtresposta = dados[0].dtresposta;
    
                }
    
                dispatch({ type: PESQUISA_LIST, payload: entidade })

                resolve(dados)
    
            }).catch(error => {
                reject(error);
            })
           
        });


        


    });



export const pesquisaAddValidacao = (id, campo, mensagem) => {
    return {
        type: PESQUISA_VALIDACAO_ADD
        , payload: { id: id, item: campo, mensagem: mensagem, status: 'Error' }
    }
}




export const pesquisaRemoveValidacao = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: PESQUISA_VALIDACAO_REMOVE, payload: [] })
        resolve([])
    }
    );

export const pesquisaSaveAction = (Entity) => (dispatch) =>
    new Promise(function (resolve, reject) {

        dispatch({ type: PESQUISA_SAVING, payload: true })

        publicIp.v4().then(ip => {

            Entity.ip = ip

            Api.savePesquisa(Entity).then(result => {

                let entidade = PesquisaEntity;
                

                if (result[0].status === 'OK') {
                    entidade.pesquisaid = -1
                    entidade.dtresposta = new Date()
                    dispatch({ type: PESQUISA_LIST, payload: entidade })
                }

                dispatch({ type: PESQUISA_SAVING, payload: false })

            })

        });






        resolve([])
    }
    );