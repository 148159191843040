const api = process.env.REACT_APP_API;



let token = localStorage.token;
if (!token) {
    token = localStorage.token = Math.random().toString(36).substr(-8);
}


const headers = {
    'Accept': 'application/json',
    'Authorization': token
}


export const getPesquisa = (chave,ip) =>
    fetch(`${api}/pesquisa/${chave}/${ip}`, {
        method: 'GET',
        headers: {
            ...headers  ,
            'Content-Type': 'application/json'
        }
}).then(res => res.json());


export const savePesquisa = (PesquisaEntity) =>
    fetch(`${api}/pesquisa/save/`, {
        method: 'POST',
        headers: {
            ...headers,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(PesquisaEntity)
    }).then(res => res.json());