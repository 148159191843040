import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import moment from 'moment';

import {
    pesquisaHandleChangeAction
    , pesquisaListAction
    , pesquisaRemoveValidacao
    , pesquisaAddValidacao
    , pesquisaSaveAction
} from '../actions/PesquisasActions';



import {
    Button, Container, Message, Grid, Header, Image, Form, Icon, Radio, Divider, Table, Segment
    , TextArea, Loader
} from 'semantic-ui-react'

class Pesquisa extends Component {

    state = {}

    //handleChange = (e, { value }) => this.setState({ value })


    handleChangePergunta01 = (e, { value }) => {
        this.props.pesquisaHandleChangeAction({ target: { 'name': 'pergunta01', 'value': value } })
    }
    handleChangePergunta02 = (e, { value }) => {
        this.props.pesquisaHandleChangeAction({ target: { 'name': 'pergunta02', 'value': value } })
    }
    handleChangePergunta03 = (e, { value }) => {
        this.props.pesquisaHandleChangeAction({ target: { 'name': 'pergunta03', 'value': value } })
    }
    handleChangePergunta04 = (e, { value }) => {
        this.props.pesquisaHandleChangeAction({ target: { 'name': 'pergunta04', 'value': value } })
    }
    handleChangePergunta05 = (e, { value }) => {
        this.props.pesquisaHandleChangeAction({ target: { 'name': 'pergunta05', 'value': value } })
    }

    constructor(props) {
        moment.locale('pt-br');
        super(props);
    }

    componentDidMount() {

        //console.log('chave:' + this.props.chave)

        this.props.pesquisaListAction(this.props.chave).then(data => { })


    }






    salvarPesquisa = () => {

        let error = 0;
        //this.setState({ formErrorMessage: [] })
        this.props.pesquisaRemoveValidacao().then(result => {

            if (this.props.PesquisaEntity.pergunta01==='') {
                this.props.pesquisaAddValidacao(1, "Pergunta 1", "É necessário responder")
                error = 1
            }

            if (this.props.PesquisaEntity.pergunta02==='') {
                this.props.pesquisaAddValidacao(2, "Pergunta 2", "É necessário responder")
                error = 1
            }

            if (this.props.PesquisaEntity.pergunta03==='') {
                this.props.pesquisaAddValidacao(3, "Pergunta 3", "É necessário responder")
                error = 1
            }

            if (this.props.PesquisaEntity.pergunta04==='') {
                this.props.pesquisaAddValidacao(4, "Pergunta 4", "É necessário responder")
                error = 1
            }

            if (this.props.PesquisaEntity.pergunta05==='') {
                this.props.pesquisaAddValidacao(5, "Pergunta 5", "É necessário responder")
                error = 1
            }

            /*if (this.props.PesquisaEntity.mensagem==='') {
                this.props.pesquisaAddValidacao(6, "Mensagem", "É necessário informar uma mensagem no campo 6")
                error = 1
            }*/


            if (this.props.validacao.length === 0) {

                //console.log('salvar')

                this.props.pesquisaSaveAction(this.props.PesquisaEntity).then(res => {
                    

                })


            }

        })

    }

    render() {

        let { chave, PesquisaEntity } = this.props

        const { value } = this.state

        return (

            <Container style={{ marginTop: '0em' }}>



                <p align="center"><b>E-mail:</b> contato@esplane.com.br&nbsp;&nbsp;&nbsp;<b>Vendas:</b> Estado de SP: (19)3785-9200  &nbsp;&nbsp;&nbsp;<b>Outros Estados:</b> 0300-785-9200  &nbsp;&nbsp;&nbsp;<b>Campinas:</b> (19)3303-7000</p>



                <Grid doubling columns={3}>
                    <Grid.Column>
                        <Image src='logo_esplane.png' size='small' />
                    </Grid.Column>
                    <Grid.Column>
                        <h1 className="ui left aligned header">Pesquisa de Satisfação</h1>
                    </Grid.Column>

                </Grid>

                <Header as='h2' dividing></Header>


                {(this.props.isLoading) ?
                    (<Loader active inline='centered' />)
                    :
                    (<div></div>)
                }


                {(this.props.PesquisaEntity.pesquisaid != 0 && this.props.PesquisaEntity.dtresposta===null) ?
                    (
                        <div>
                            <Message info>
                                <p align="center">
                                    Gostaríamos que nos ajudasse a atendê-lo melhor. Para isso, poderia nos avaliar respondendo a pesquisa abaixo, por favor?<br /><br />

                                    <b>Cliente:</b> "{`${this.props.PesquisaEntity.cliente}`}"<br />
                                                
                                    Visita realizada em <b>{moment(this.props.PesquisaEntity.dtvisita).format('DD/MM/YYYY')}</b> pelo Consultor <b>"{`${this.props.PesquisaEntity.consultor}`}" </b>
                                </p>
                            </Message>



                            <Form>

                                <Form.Field>

                                    <p>{`1 - Qual a sua satisfação em relação ao atendimento do nosso consultor "${this.props.PesquisaEntity.consultor}" ?`}</p>
                                    <Table singleLine structured compact='very'>
                                        <Table.Header>
                        
                                        </Table.Header>

                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        
                                                        <Icon name='question circle outline' size='big' />
                                                        <Radio
                                                            key='pergunta01_F'
                                                            name='pergunta01'
                                                            value='6'
                                                            checked={this.props.PesquisaEntity.pergunta01 === '6'}
                                                            onChange={this.handleChangePergunta01.bind(this)}
                                                        /> 
                                                        <label>Não consigo avaliar</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>


                                                <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        <Icon name='frown' color='red' size='big' />
                                                        <Radio
                                                            key='pergunta01_A'
                                                            name='pergunta01'
                                                            value='1'
                                                            checked={this.props.PesquisaEntity.pergunta01 === '1'}
                                                            onChange={this.handleChangePergunta01.bind(this)}
                                                        /> 
                                                        <label>Muito Insatisfeito</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>


                                                <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        
                                                        <Icon name='frown outline' color='orange' size='big' />
                                                        <Radio
                                                            key='pergunta01_B'
                                                            name='pergunta01'
                                                            value='2'
                                                            checked={this.props.PesquisaEntity.pergunta01 === '2'}
                                                            onChange={this.handleChangePergunta01.bind(this)}
                                                        /> 
                                                        <label>Pouco Insatisfeito</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>

                                                <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        
                                                        <Icon name='meh' color='yellow' size='big' />
                                                        <Radio
                                                            key='pergunta01_C'
                                                            name='pergunta01'
                                                            value='3'
                                                            checked={this.props.PesquisaEntity.pergunta01 === '3'}
                                                            onChange={this.handleChangePergunta01.bind(this)}
                                                        /> 
                                                        <label>Neutro</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>

                                                 <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        
                                                        <Icon name='smile outline' color='blue' size='big' />
                                                        <Radio
                                                            key='pergunta01_D'
                                                            name='pergunta01'
                                                            value='4'
                                                            checked={this.props.PesquisaEntity.pergunta01 === '4'}
                                                            onChange={this.handleChangePergunta01.bind(this)}
                                                        /> 
                                                        <label>Pouco Satisfeito</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>

                                              
                                              <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        
                                                        <Icon name='smile' color='green' size='big' />
                                                        <Radio
                                                            key='pergunta01_E'
                                                            name='pergunta01'
                                                            value='5'
                                                            checked={this.props.PesquisaEntity.pergunta01 === '5'}
                                                            onChange={this.handleChangePergunta01.bind(this)}
                                                        /> 
                                                        <label>Muito Satisfeito</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>
                                               
                                                
                                            </Table.Row>

                                        </Table.Body>
                                    </Table>

                                    <Divider />

                                </Form.Field>








                                <Form.Field>

                                    <p>{`2 - Qual a sua satisfação em relação ao atendimento do nosso vendedor "${this.props.PesquisaEntity.vendedor}" ?`}</p>
                                    <Table singleLine structured compact='very'>
                                        <Table.Header>
                        
                                        </Table.Header>

                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        
                                                        <Icon name='question circle outline' size='big' />
                                                        <Radio
                                                            key='pergunta02_F'
                                                            name='pergunta02'
                                                            value='6'
                                                            checked={this.props.PesquisaEntity.pergunta02 === '6'}
                                                            onChange={this.handleChangePergunta02.bind(this)}
                                                        /> 
                                                        <label>Não consigo avaliar</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>


                                                <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        <Icon name='frown' color='red' size='big' />
                                                        <Radio
                                                            key='pergunta02_A'
                                                            name='pergunta02'
                                                            value='1'
                                                            checked={this.props.PesquisaEntity.pergunta02 === '1'}
                                                            onChange={this.handleChangePergunta02.bind(this)}
                                                        /> 
                                                        <label>Muito Insatisfeito</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>


                                                <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        
                                                        <Icon name='frown outline' color='orange' size='big' />
                                                        <Radio
                                                            key='pergunta02_B'
                                                            name='pergunta02'
                                                            value='2'
                                                            checked={this.props.PesquisaEntity.pergunta02 === '2'}
                                                            onChange={this.handleChangePergunta02.bind(this)}
                                                        /> 
                                                        <label>Pouco Insatisfeito</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>

                                                <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        
                                                        <Icon name='meh' color='yellow' size='big' />
                                                        <Radio
                                                            key='pergunta02_C'
                                                            name='pergunta02'
                                                            value='3'
                                                            checked={this.props.PesquisaEntity.pergunta02 === '3'}
                                                            onChange={this.handleChangePergunta02.bind(this)}
                                                        /> 
                                                        <label>Neutro</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>

                                                 <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        
                                                        <Icon name='smile outline' color='blue' size='big' />
                                                        <Radio
                                                            key='pergunta02_D'
                                                            name='pergunta02'
                                                            value='4'
                                                            checked={this.props.PesquisaEntity.pergunta02 === '4'}
                                                            onChange={this.handleChangePergunta02.bind(this)}
                                                        /> 
                                                        <label>Pouco Satisfeito</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>

                                              
                                              <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        
                                                        <Icon name='smile' color='green' size='big' />
                                                        <Radio
                                                            key='pergunta02_E'
                                                            name='pergunta02'
                                                            value='5'
                                                            checked={this.props.PesquisaEntity.pergunta02 === '5'}
                                                            onChange={this.handleChangePergunta02.bind(this)}
                                                        /> 
                                                        <label>Muito Satisfeito</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>
                                               
                                                
                                            </Table.Row>

                                        </Table.Body>
                                    </Table>

                                    <Divider />

                                </Form.Field>





                                <Form.Field>

                                    <p>3 - Qual a sua satisfação em relação à nossa área Comercial como um todo?</p>
                                    <Table singleLine structured compact='very'>
                                        <Table.Header>
                        
                                        </Table.Header>

                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        
                                                        <Icon name='question circle outline' size='big' />
                                                        <Radio
                                                            key='pergunta03_F'
                                                            name='pergunta03'
                                                            value='6'
                                                            checked={this.props.PesquisaEntity.pergunta03 === '6'}
                                                            onChange={this.handleChangePergunta03.bind(this)}
                                                        /> 
                                                        <label>Não consigo avaliar</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>


                                                <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        <Icon name='frown' color='red' size='big' />
                                                        <Radio
                                                            key='pergunta03_A'
                                                            name='pergunta03'
                                                            value='1'
                                                            checked={this.props.PesquisaEntity.pergunta03 === '1'}
                                                            onChange={this.handleChangePergunta03.bind(this)}
                                                        /> 
                                                        <label>Muito Insatisfeito</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>


                                                <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        
                                                        <Icon name='frown outline' color='orange' size='big' />
                                                        <Radio
                                                            key='pergunta03_B'
                                                            name='pergunta03'
                                                            value='2'
                                                            checked={this.props.PesquisaEntity.pergunta03 === '2'}
                                                            onChange={this.handleChangePergunta03.bind(this)}
                                                        /> 
                                                        <label>Pouco Insatisfeito</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>

                                                <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        
                                                        <Icon name='meh' color='yellow' size='big' />
                                                        <Radio
                                                            key='pergunta03_C'
                                                            name='pergunta03'
                                                            value='3'
                                                            checked={this.props.PesquisaEntity.pergunta03 === '3'}
                                                            onChange={this.handleChangePergunta03.bind(this)}
                                                        /> 
                                                        <label>Neutro</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>

                                                 <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        
                                                        <Icon name='smile outline' color='blue' size='big' />
                                                        <Radio
                                                            key='pergunta03_D'
                                                            name='pergunta03'
                                                            value='4'
                                                            checked={this.props.PesquisaEntity.pergunta03 === '4'}
                                                            onChange={this.handleChangePergunta03.bind(this)}
                                                        /> 
                                                        <label>Pouco Satisfeito</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>

                                              
                                              <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        
                                                        <Icon name='smile' color='green' size='big' />
                                                        <Radio
                                                            key='pergunta03_E'
                                                            name='pergunta03'
                                                            value='5'
                                                            checked={this.props.PesquisaEntity.pergunta03 === '5'}
                                                            onChange={this.handleChangePergunta03.bind(this)}
                                                        /> 
                                                        <label>Muito Satisfeito</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>
                                               
                                                
                                            </Table.Row>

                                        </Table.Body>
                                    </Table>

                                    <Divider />

                                </Form.Field>






                                <Form.Field>

                                    <p>4 - Qual a sua satisfação em relação à nossa área Financeira?</p>
                                    <Table singleLine structured compact='very'>
                                        <Table.Header>
                        
                                        </Table.Header>

                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        
                                                        <Icon name='question circle outline' size='big' />
                                                        <Radio
                                                            key='pergunta04_F'
                                                            name='pergunta04'
                                                            value='6'
                                                            checked={this.props.PesquisaEntity.pergunta04 === '6'}
                                                            onChange={this.handleChangePergunta04.bind(this)}
                                                        /> 
                                                        <label>Não consigo avaliar</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>


                                                <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        <Icon name='frown' color='red' size='big' />
                                                        <Radio
                                                            key='pergunta04_A'
                                                            name='pergunta04'
                                                            value='1'
                                                            checked={this.props.PesquisaEntity.pergunta04 === '1'}
                                                            onChange={this.handleChangePergunta04.bind(this)}
                                                        /> 
                                                        <label>Muito Insatisfeito</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>


                                                <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        
                                                        <Icon name='frown outline' color='orange' size='big' />
                                                        <Radio
                                                            key='pergunta04_B'
                                                            name='pergunta04'
                                                            value='2'
                                                            checked={this.props.PesquisaEntity.pergunta04 === '2'}
                                                            onChange={this.handleChangePergunta04.bind(this)}
                                                        /> 
                                                        <label>Pouco Insatisfeito</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>

                                                <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        
                                                        <Icon name='meh' color='yellow' size='big' />
                                                        <Radio
                                                            key='pergunta04_C'
                                                            name='pergunta04'
                                                            value='3'
                                                            checked={this.props.PesquisaEntity.pergunta04 === '3'}
                                                            onChange={this.handleChangePergunta04.bind(this)}
                                                        /> 
                                                        <label>Neutro</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>

                                                 <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        
                                                        <Icon name='smile outline' color='blue' size='big' />
                                                        <Radio
                                                            key='pergunta04_D'
                                                            name='pergunta04'
                                                            value='4'
                                                            checked={this.props.PesquisaEntity.pergunta04 === '4'}
                                                            onChange={this.handleChangePergunta04.bind(this)}
                                                        /> 
                                                        <label>Pouco Satisfeito</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>

                                              
                                              <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        
                                                        <Icon name='smile' color='green' size='big' />
                                                        <Radio
                                                            key='pergunta04_E'
                                                            name='pergunta04'
                                                            value='5'
                                                            checked={this.props.PesquisaEntity.pergunta04 === '5'}
                                                            onChange={this.handleChangePergunta04.bind(this)}
                                                        /> 
                                                        <label>Muito Satisfeito</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>
                                               
                                                
                                            </Table.Row>

                                        </Table.Body>
                                    </Table>

                                    <Divider />

                                </Form.Field>





                                <Form.Field>

                                    <p>5 - Qual a sua satisfação em relação à nossa área de Logística?</p>
                                    <Table singleLine structured compact='very'>
                                        <Table.Header>
                        
                                        </Table.Header>

                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        
                                                        <Icon name='question circle outline' size='big' />
                                                        <Radio
                                                            key='pergunta05_F'
                                                            name='pergunta05'
                                                            value='6'
                                                            checked={this.props.PesquisaEntity.pergunta05 === '6'}
                                                            onChange={this.handleChangePergunta05.bind(this)}
                                                        /> 
                                                        <label>Não consigo avaliar</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>


                                                <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        <Icon name='frown' color='red' size='big' />
                                                        <Radio
                                                            key='pergunta05_A'
                                                            name='pergunta05'
                                                            value='1'
                                                            checked={this.props.PesquisaEntity.pergunta05 === '1'}
                                                            onChange={this.handleChangePergunta05.bind(this)}
                                                        /> 
                                                        <label>Muito Insatisfeito</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>


                                                <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        
                                                        <Icon name='frown outline' color='orange' size='big' />
                                                        <Radio
                                                            key='pergunta05_B'
                                                            name='pergunta05'
                                                            value='2'
                                                            checked={this.props.PesquisaEntity.pergunta05 === '2'}
                                                            onChange={this.handleChangePergunta05.bind(this)}
                                                        /> 
                                                        <label>Pouco Insatisfeito</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>

                                                <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        
                                                        <Icon name='meh' color='yellow' size='big' />
                                                        <Radio
                                                            key='pergunta05_C'
                                                            name='pergunta05'
                                                            value='3'
                                                            checked={this.props.PesquisaEntity.pergunta05 === '3'}
                                                            onChange={this.handleChangePergunta05.bind(this)}
                                                        /> 
                                                        <label>Neutro</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>

                                                 <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        
                                                        <Icon name='smile outline' color='blue' size='big' />
                                                        <Radio
                                                            key='pergunta05_D'
                                                            name='pergunta05'
                                                            value='4'
                                                            checked={this.props.PesquisaEntity.pergunta05 === '4'}
                                                            onChange={this.handleChangePergunta05.bind(this)}
                                                        /> 
                                                        <label>Pouco Satisfeito</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>

                                              
                                              <Table.Cell textAlign='center'>
                                                    <div>
                                                        <div>
                                                        
                                                        <Icon name='smile' color='green' size='big' />
                                                        <Radio
                                                            key='pergunta05_E'
                                                            name='pergunta05'
                                                            value='5'
                                                            checked={this.props.PesquisaEntity.pergunta05 === '5'}
                                                            onChange={this.handleChangePergunta05.bind(this)}
                                                        /> 
                                                        <label>Muito Satisfeito</label>
                                                        </div>
                                                    </div>
                                                </Table.Cell>
                                               
                                                
                                            </Table.Row>

                                        </Table.Body>
                                    </Table>

                                    <Divider />

                                </Form.Field>

                                <p>6 - Deixe abaixo alguma observação, insatisfação,elogio ou o que desejar sobre a Esplane:</p>
                                <Form.Field control={TextArea} label=''
                                    id='mensagem'
                                    name='mensagem'
                                    placeholder='Digite a mensagem...'
                                    onChange={this.props.pesquisaHandleChangeAction.bind(this)}
                                    maxLength={2000}
                                />

                                <Form.Field>

                                {
                                    (this.props.validacao.length > 0 ? (
                                        <Message negative>
                                            {this.props.validacao.map(valida => (
                                                <p key={valida.id}>{valida.item} - {valida.mensagem}</p>
                                            ))}

                                        </Message>
                                    ) : (
                                            <div></div>
                                        ))
                                }

                                    <p align="center">
                                        <Button primary
                                         loading={this.props.isSaving}
                                         onClick={this.salvarPesquisa.bind(this)}
                                        >
                                            Enviar
                                    </Button>
                                    </p>
                                    <br /><br /><br />
                                </Form.Field>




                            </Form>

                        </div>
                    )
                    :
                    (

                        <div>
                        {(this.props.PesquisaEntity.dtresposta==null)?
                        (<div></div>)
                        :
                        (

                        
                        (this.props.PesquisaEntity.pesquisaid===-1)?
                        (<Message success>
                        <p align="center">
                            Muito obrigado por participar de nossa pesquisa de satisfação!
                        </p>
                        </Message>)
                        :
                        (<Message success>
                            <p align="center">
                                {`A pesquisa referente à visita do Consultor ${this.props.PesquisaEntity.consultor} no dia ${moment(this.props.PesquisaEntity.dtvisita).format('DD/MM/YYYY')} já foi respondida no dia ${moment(this.props.PesquisaEntity.dtresposta).format('DD/MM/YYYY')}. Obrigado! `}
                            </p>
                            </Message>)
                        )}
                        </div>                        
                    
                    )
                }



            </Container>
        )
    }
}


Pesquisa.defaultProps = {
    chave: ''
};

Pesquisa.propTypes = {
    chave: PropTypes.string.isRequired
};



const mapStateToProps = state => (
    {
        isLoading: state.PesquisaReducer.isLoading,
        PesquisaEntity: state.PesquisaReducer.PesquisaEntity,
        validacao: state.PesquisaReducer.validacao,
        isSaving: state.PesquisaReducer.isSaving
        
    }
);


export default withRouter(connect(mapStateToProps, {
    pesquisaHandleChangeAction
    , pesquisaListAction
    , pesquisaRemoveValidacao
    , pesquisaAddValidacao
    , pesquisaSaveAction
}
)(Pesquisa));

