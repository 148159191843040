export const PesquisaEntity = {
    pesquisaid:0
    ,chave:''
    ,codpac:0
    ,cliente:''
    ,codconsultor:0
    ,consultor:''
    ,codvend:0
    ,vendedor:''
    ,dtvisita:null
    ,dtresposta:null
    ,ip:''
    ,pergunta01:''
    ,pergunta02:''
    ,pergunta03:''
    ,pergunta04:''
    ,pergunta05:''
    ,mensagem:''
}