import React, { Component } from 'react';

import { BrowserRouter as Router, Route } from "react-router-dom";

import Pesquisa from './components/Pesquisa'




const ParamsExample = () => (
  <Router>
    <div>
      <Route path="/:chave" component={Child} />
    </div>
  </Router>
);

const Child = ({ match }) => (

    <Pesquisa chave={match.params.chave}/>

);


export default ParamsExample;
