import {
    PESQUISA_LIST
   , PESQUISA_CHANGE
   ,PESQUISA_LOADING
   , PESQUISA_VALIDACAO_ADD
   , PESQUISA_VALIDACAO_REMOVE
   , PESQUISA_SAVING
} from '../actions/ActionsTypes';

import {PesquisaEntity} from '../entities/PesquisaEntity'


const INITIAL_STATE = {
    isLoading:true
    ,PesquisaEntity
    ,validacao:[]
    , isSaving:false
}


export default (state= INITIAL_STATE,action)=>{
    switch(action.type){
        case PESQUISA_LIST:
            return {...state, PesquisaEntity: action.payload}
        case PESQUISA_CHANGE:
            return {...state, PesquisaEntity:{...state.PesquisaEntity, [action.field]:action.payload}}
        case PESQUISA_LOADING:
            return {...state,isLoading:action.payload}

        case PESQUISA_VALIDACAO_ADD:          
            return { ...state, validacao: [...state.validacao, action.payload] }

        case PESQUISA_VALIDACAO_REMOVE:
            return { ...state, validacao: [] }

        
        case PESQUISA_SAVING:
            return { ...state, isSaving: action.payload }

        default: 
            return state;
    }
}
